<template>
  <div id="message">
    <h1 id="icon">:(</h1>
    <h2>Oops...</h2>
    <p>Ez roppant kínos, de úgy fest, eltévedtél...</p>
    <p>De az is lehet, hogy a weboldal tulajdonosa még nem áll készen arra, hogy megmutassa magát. Ezesetben látogass vissza később!</p>
  </div>
</template>

<script>
import HelloWorld from './components/HelloWorld.vue'

export default {
  name: 'App',
  components: {
    HelloWorld
  }
}
</script>

<style lang="scss">
@import '~@/scss/main.scss';
h1 {
  font-family: 'Montserrat', sans-serif;
  color: #bfbfbf;
  font-weight: 300;
  font-size: 14rem;
  text-align: center;
  margin-block: 0;
}
h2 {
  font-family: 'Montserrat', sans-serif;
  color: #bfbfbf;
  font-weight: 300;
  font-size: 4rem;
  text-align: center;
}
p {
  font-family: 'Montserrat', sans-serif;
  color: #bfbfbf;
  font-weight: 300;
  font-size: 1.5rem;  
  text-align: center;
}
#message {
  // background-color: red;
  font-family: 'Montserrat', sans-serif;
   margin: 0;
  position: absolute;
  width: 75%;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%,-50%);
  transform: translate(-50%,-50%);
}
</style>
